<template>
    <v-card flat>
        <v-card-text>
            <v-row class="mb-3">

                <v-col :key="index" md="6" v-for="(location,index) in locations">
                    <v-card class="mx-auto" max-width="600">
                        <v-card-title>
                            {{location.country}}, {{location.state}}, {{location.city}}  <v-icon :color="location.is_active ? 'green' : 'red'"> fas fa-circle-dot</v-icon>
                            <v-spacer></v-spacer>

                            <div>
                                <v-chip
                                        class="ma-2"
                                        color="success"
                                        small
                                >
                                    <v-icon left small>
                                        fas fa-university
                                    </v-icon>
                                    {{location.type=='main' ? 'Main' : 'Branch'}}
                                </v-chip>

                                <v-btn
                                        class="ml-2"
                                        color="blue"
                                        icon
                                        x-small
                                        @click="__editLocation(location)"
                                >
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on">fas fa-edit</v-icon>
                                        </template>
                                        Edit
                                    </v-tooltip>

                                </v-btn>
                                <v-btn
                                        class="ml-2"
                                        color="red"
                                        icon
                                        x-small
                                        @click="__delete(location) "
                                >
                                    <v-tooltip left>
                                        <template v-slot:activator="{ on }">
                                            <v-icon v-on="on">fas fa-trash</v-icon>
                                        </template>
                                        Delete
                                    </v-tooltip>

                                </v-btn>
                            </div>
                        </v-card-title>

                        <v-card-subtitle>
                            <v-icon small>
                                fas fa-map-marker-alt
                            </v-icon>
                          &nbsp; {{location.address_line_1}}, {{location.address_line_2}}
                        </v-card-subtitle>
                        <!-- <v-card-text>
                            <div class="mb-2">
                                <v-icon small>
                                    fas fa-envelope
                                </v-icon>
                                &nbsp;{{location.university_email}}
                            </div>
                            <div>
                                <v-icon small>
                                    fas fa-phone
                                </v-icon>
                              &nbsp;{{location.country_code}} {{location.phone_no}}
                            </div>
                            <div v-html="location.google_map" v-if="location.google_map">

                            </div>


                        </v-card-text> -->

                    </v-card>
                </v-col>
                <v-col cols="12">
                    Branch Location
                </v-col>
                <v-col cols="12" md="4">
                    <v-autocomplete :loading="countryLoading" :filter="customFilter" :items="countries" @change="changeCountry()"
                            dense item-text="name" item-value="id" :error="$v.location.country_id.$error"
                            label="Country " outlined
                            prepend-inner-icon="fas fa-globe-americas" return-object v-model="country">
                        <template v-slot:item="slotProps">
                            <i :class="`em em-flag-${slotProps.item.iso_2.toLowerCase()}`" class="mr-2"></i>
                            {{slotProps.item.title}}
                        </template>
                        <template v-slot:selection="data">
                            <i :class="`em em-flag-${data.item.iso_2.toLowerCase()}`" class="mr-2"></i>
                            {{data.item.title}}
                        </template>
                    </v-autocomplete>
                  <span class="text-danger" v-if="$v.location.country_id.$error">This Country Field is Required</span>

                </v-col>
                <v-col cols="12" md="4">
                    <v-autocomplete
                            :items="states"
                            :loading="stateLoading"
                            @change="getAllCity()"
                            dense
                            item-text="name"
                            item-value="id"
                            label="States "
                            outlined
                            v-model="location.state_id"
                    >
                        <template v-slot:item="slotProps">
                            {{slotProps.item.name}}
                        </template>
                        <template v-slot:selection="data">

                            {{data.item.name}}
                        </template>
                    </v-autocomplete>

                </v-col>
                <v-col cols="12" md="4">
                    <v-autocomplete
                            :items="cities"
                            :loading="cityLoading"
                            dense
                            item-text="name"
                            item-value="id"
                            label="City "
                            outlined
                            v-model="location.city_id"
                    >
                        <template v-slot:item="slotProps">
                            {{slotProps.item.name}}
                        </template>
                        <template v-slot:selection="data">

                            {{data.item.name}}
                        </template>
                    </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field
                            dense
                            label="Address Line 1"
                            outlined
                            v-model="location.address_line_1"
                    >

                    </v-text-field>

                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field dense label="Address Line 2" outlined
                            v-model="location.address_line_2">
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                    <VuePhoneNumberInput :default-country-code="default_country_code"
                                         @update="phoneChanged"
                                         v-model="location.phone_no"/>
                </v-col>
                <v-col cols="12" md="3">
                    <v-text-field dense label="University Email"
                                  outlined
                                  prepend-inner-icon="fas fa-envelope"
                                  v-model="location.university_email">
                    </v-text-field>

                </v-col>
                <v-col class="text-left" cols="8" md="3">
                    <v-text-field dense
                            label="Postal Code"
                            outlined
                            prepend-inner-icon="fas fa-map" v-model="location.postal_code">
                    </v-text-field>
                </v-col>
                <v-col class="text-left" cols="8" md="3">
                    <v-select
                            :items="types"
                            item-value="value"
                            item-text="text"
                            outlined
                            dense
                            label="Type"
                            v-model="location.type">

                    </v-select>

                </v-col>
                <v-col class="text-left" cols="6">
                    <v-textarea dense label="Embedded Google Maps" outlined
                                v-model="location.google_map"></v-textarea>
                </v-col>
                <v-col class="text-left" cols="6">
                    <v-textarea dense label="Embedded Google Street Maps" outlined
                                v-model="location.google_map_street"></v-textarea>
                </v-col>
                <v-col class="text-left" cols="6">
                    <v-switch dense label="Status" outlined
                                v-model="location.is_active"></v-switch>
                </v-col>

            </v-row>
          <v-row>
            <v-col cols="12 text-right">
              <v-btn

                  @click="createOrUpdate"
                  class="btn btn-primary"
                  medium
              >Save
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    import LocationService from "@/services/academic/institution/location/LocationService";
    import CountryService from "@/services/country/CountryService";
    import StateService from "@/services/country/state/StateService";
    import CityService from "@/services/country/city/CityService";
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import {required,email,numeric,requiredIf} from "vuelidate/lib/validators";
    import InstitutionService from "@/services/academic/institution/InstitutionService";

    const state = new StateService();
    const country = new CountryService();
    const cityService = new CityService();
    const locationService = new LocationService();
    const institutionService = new InstitutionService();

    export default {
        name: "Locations",
        props: ['university', 'university_id'],
        components: {
            VuePhoneNumberInput,
        },
        validations: {
          location: {
            country_id:{required}
          }
        },
        data() {
            return {
                locations: [],
                types:[
                  {value:'main',text:'Main'},
                  {value:'branch',text:'Branch'},
                ],
                countries: [],
                states: [],
                cities: [],
                country: null,
                isLoading: false,
                stateLoading: false,
                cityLoading: false,
                countryLoading: false,
                default_country_code: 'NP',
                errors:[],
                location: {
                  country_id:'',
                  state_id:'',
                  city_id:'',
                  address_line_1:'',
                  address_line_2:'',
                  phone_no:'',
                  postal_code:'',
                  google_map:'',
                  google_map_street:'',
                  university_email:'',
                  type:'',
                  is_active: true,
                },
            }
        },
        computed: {
            institution_id() {
              if(this.university_id)
                return this.university_id;
              return this.$route.params.institution_id;
            }
        },
        methods: {
            getCountries() {
                country
                    .getAllCountry({'is_operating_country': 1})
                    .then((response) => {
                        this.countries = response.data.data;
                    })
                    .catch((error) => {
                    })
            },
            getLocations() {
              locationService
                    .all(this.institution_id)
                    .then((response) => {
                        this.locations = response.data.data;
                    })
                    .catch((error) => {
                    })
            },
            changeCountry() {
                this.location.country_id = this.country.id;
                this.location.country_code = this.country.phone_code;
                this.default_country_code = this.country.iso_2;
                this.getAllState()
            },
            customFilter(item, queryText, itemText) {
                const textOne = item.title.toLowerCase()
                const searchText = queryText.toLowerCase()
                return textOne.indexOf(searchText) > -1
            },
            getAllState() {
                this.stateLoading = true;
                state
                    .all(this.location.country_id)
                    .then(response => {
                        this.states = response.data.data;
                    })
                    .catch((err) => {
                    }).finally(() => {
                    this.stateLoading = false;
                });
            },
            createOrUpdate() {
                 this.$v.$touch()
                 if (this.$v.$error) {
                    setTimeout(() => {
                         this.$v.$reset();
                     }, 3000);
                 } else {
                   this.location.institution_id = this.university.id ? this.university.id : this.university_id;
                   this.loading = true;
                   if (!this.edit)
                     this._create();
                   else this.update();
                 }
            },
            __editLocation(location) {
                this.edit = true;
                this.location=location;
                this.getCountryById()
                this.getAllCity(this.location.state_id)
            },
            resetForm(){
              this.edit = false;
              this.country='';
              this.$v.$reset();
              this.location= {
                    country_id:'',
                    state_id:'',
                    city_id:'',
                    address_line_1:'',
                    address_line_2:'',
                    phone_no:'',
                    postal_code:'',
                    google_map:'',
                    google_map_street:'',
                    university_email:'',
                    type:'',
                    is_active: true,
              };
            },
            _create: function () {
                locationService
                    .create(this.location)
                    .then(response => {
                        this.loading = false;
                        this.$snotify.success("Locations created successfully");
                        this.resetForm();
                        this.getLocations()

                    })
                    .catch((err) => {
                        this.loading = false;
                        this.$snotify.error("Oops something went wrong");
                        this.errors = err.errors;
                    });
            },
            update: function () {
                locationService
                    .update(this.location.id,this.location)
                    .then(response => {
                        this.loading = false;
                        this.$snotify.success("Locations Updated successfully");
                        this.resetForm();
                        this.getLocations()

                    })
                    .catch((err) => {

                      this.loading = false;
                        this.$snotify.error("Oops something went wrong");
                        this.errors = err.errors;
                    });
            },
            getCountryById(){
                this.countryLoading = true;
                country.getById(this.location.country_id).then(res=>{
                    this.country = res.data.country;
                    this.changeCountry();
                }).catch(error=>{
                }).finally(()=>{
                    this.countryLoading = false;
                })
            },
            __delete(item) {
                this.$confirm({
                    message: `Are you sure? `,
                    button: {
                        no: "No",
                        yes: "Yes",
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: (confirm) => {
                        if (confirm) {
                            locationService
                                .delete(item.id)
                                .then((response) => {
                                    this.getLocations();
                                    this.$snotify.success("Locations Successfully Deleted");
                                })
                                .catch((err) => {
                                    this.isLoading = false;
                                    this.$snotify.error("Oops something went wrong");
                                });
                        }
                    },
                });
            },
            getAllCity() {
                this.cityLoading = true;
                cityService
                    .all(this.location.state_id)
                    .then(response => {
                        this.cities = response.data.data;
                        this.cityLoading = false;
                    })
                    .catch((err) => {
                    });
            },
            phoneChanged(phone) {
                this.location.country_code = phone.countryCallingCode;
            },
        },

        mounted()
        {
          if(this.university_id) {
            this.location.institution_id = this.university_id;
          } else {
            this.location.institution_id = this.university.id
          }
          this.getLocations();
          this.getCountries();
        }
    }

</script>

<style scoped>

</style>