<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              Required Document
            </v-col>
            <v-col cols="6" class="text-right">
              <v-btn class="btn btn-primary text-white" depressed :loading="loading" @click="manageRequiredDocument">Add
                Required Document
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="documents && documents.length">
              <v-list subheader two-line>
                <v-list-item
                    v-for="document in documents"
                    :key="document.title">
                  <v-list-item-avatar>
                    <v-icon
                        class="grey lighten-1"
                        dark>
                      {{ document.icon }}
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-text="document.title"></v-list-item-title>

                    <v-list-item-subtitle v-text="document.type"></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn :loading="loading"
                           @click="deleteRequiredDocument(document)"
                           class="mx-2"
                           fab
                           dark
                           small
                           color="success"
                    >
                      <v-icon dark>
                        fas fa-trash
                      </v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider inset></v-divider>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <required-document-form
        :type="'institution'"
        :dialog="document_dialog"
        :reference_id="item.id"
        @close_dialog="manageRequiredDocument({},'close')"></required-document-form>
  </v-card>
</template>
<script>
import RequiredDocumentService from "@/services/required-document/RequiredDocumentService";
import RequiredDocumentForm from "@/view/component/required-document/Form";
import RequiredDocumentValueService from "@/services/required-document/RequiredDocumentValueService";

const requiredDocumentService = new RequiredDocumentService();
const valueService = new RequiredDocumentValueService();

export default {
  name: "required-document",
  props: ['item'],
  components: {
    RequiredDocumentForm
  },
  data() {
    return {
      documents: [],
      document_dialog: false,
      loading: false,
    }
  }, mounted() {
    this.getRequiredDocuments();
  }, methods: {
    getRequiredDocuments() {
      requiredDocumentService.getByInstitution(this.item.id).then(response => {
        this.documents = response.data.required_documents
      }).catch(error => {
        this.documents = [];
      })
    },
    manageRequiredDocument(document = {}, type = 'open') {
      if (type == 'open') {
        this.document_dialog = true
      } else {
        this.document_dialog = false
        this.getRequiredDocuments();
      }
    }, deleteRequiredDocument(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true
            valueService.delete(item.required_document_id, item.id).then(response => {
              this.$snotify.success("Required Document has been deleted successfully");
            }).catch(error => {
              this.$snotify.error("Something went wrong please try again later")
            }).finally(() => {
              this.loading = false
              this.getRequiredDocuments();
            })
          }
        }
      });
    }
  }
}
</script>

<style scoped>

</style>