import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class FeatureService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/feature';
    }

    paginate(data = {}, index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }

    allByProgram(programId) {
        let url = `${this.#api}/program/${programId}`
        return apiService.get(url)
    }
    allByInstitution(instituteId) {
        let url = `${this.#api}/institution/${instituteId}`
        return apiService.get(url)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    delete(examOptionId) {
        let url = `${this.#api}/${examOptionId}`
        return apiService.delete(url)
    }

    findDetail(data = {}) {
        let url = `${this.#api}/find/detail`;
        let param = {
            params: data
        }
        return apiService.query(url, param);
    }
}
