<template>
  <v-card flat>
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              Features
            </v-col>
            <v-col cols="6" class="text-right">
              <v-spacer></v-spacer>
              <v-btn class="btn btn-primary text-white" @click="manageFeature({}, 'open')">
                <i class="fas fa-plus"></i>
                Add Feature
              </v-btn>
            </v-col>
            <v-col cols="12" v-if="features && features.length">
              <v-list
                  subheader
                  two-line
              >
                <v-list-item
                    v-for="attribute in features"
                    :key="attribute.title"
                >
                  <v-list-item-avatar>
                    <v-icon
                        class="grey lighten-1"
                        dark
                    >
                      {{ attribute.icon }}
                    </v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>
                      {{ attribute.attribute }}
                      <i class="fas fa-circle font-size-sm"
                         :class="attribute.is_active?'text-success':'text-danger'"></i>
                    </v-list-item-title>

                    <v-list-item-subtitle v-text="attribute.type"></v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <span
                        @click="manageFeature(attribute, 'open')"

                    >
                       <i class="fas fa-edit">

                      </i>
                    </span>
                    <span

                        @click="deleteFeature(attribute.id)"
                    >
                      <i class="fas fa-trash">

                      </i>
                    </span>
                  </v-list-item-action>
                </v-list-item>
                <v-divider inset></v-divider>
              </v-list>
            </v-col>
            <v-col cols="12" class="text-center mt-5" v-else>
              <h4>No data found</h4>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="900" persistent>
      <v-card>
        <v-card-title>
          Features
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-select :disabled="feature.id"
                        :items="attributes" item-text="title" item-value="id"
                        outlined dense label="Attribute"
                        v-model="feature.attribute_id"
                        :error="$v.feature.attribute_id.$error"
              >
              </v-select>
              <span class="text-danger" v-if="$v.feature.attribute_id.$error">This field is required</span>
            </v-col>
            <v-col md="12">
              <v-select
                  :items="types" item-text="text" item-value="value"
                  outlined dense label="Type"
                  v-model="feature.type">
              </v-select>
            </v-col>
            <v-col md="12" v-if="feature.type == 'link'">
              <v-text-field label="Link"
                            outlined dense
                            v-model="feature.link"
                            :error="$v.feature.link.$error"
              >
              </v-text-field>
              <span class="text-danger" v-if="$v.feature.link.$error">Must be a valid url</span>
            </v-col>
            <v-col md="12" v-if="feature.type == 'description'">
              <ckeditor
                  :config="editorConfig"
                  v-model="feature.description"
              >
              </ckeditor>
              <!--              :error="$v.feature.link.$error"-->
              <span class="text-danger" v-if="$v.feature.description.$error">This field is required</span>
            </v-col>
            <v-col cols="12" md="6">
              Status
              <v-switch
                  :label="feature.is_active ? 'Active' : 'Inactive'"
                  v-model="feature.is_active"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              @click="manageFeature"
              color="mr-2" text dark
              medium
          >
            Cancel
          </v-btn>
          <v-btn
              @click.prevent="createOrUpdate"
              color="ml-4 btn btn-primary"
              medium
              :loading="loading"
          >
            Save
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>


<script>
import AttributeService from "@/services/academic/attribute/AttributeService";

const attributeService = new AttributeService();
import FeatureService from "@/services/academic/institution/feature/FeatureService";
import {required, url} from "vuelidate/lib/validators";

const featureService = new FeatureService();
export default {
  name: "Features",
  props: ['university'],
  validations() {
    return {
      feature: this.feature_rule
    }
  },
  computed: {
    institution_id() {
      return this.$route.params.institution_id;
    },
    feature_rule() {
      let rule = {
        attribute_id: {required},
        institution_id: {required},
      }
      if (this.feature.type == "description") {
        rule.description = {required}
      }
      if (this.feature.type == "link") {
        rule.link = {required, url}
      }
      return rule;
    }
  },
  data() {
    return {
      attributes: [],
      feature: {
        attribute_id: null,
        institution_id: null,
        type: 'description',
        description: null
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
      },
      loading: false,
      dialog: false,
      edit: false,
      featureDetail: {},
      features: [],
      types: [
        {text: 'Description', value: 'description'},
        {text: 'Link', value: 'link'}
      ]
    }
  },mounted() {
    this.__getAllAttributes();
    this.getAllFeatures();
  },
  methods: {
    __getAllAttributes() {
      this.loading = true;
      attributeService
          .paginate({'type': 'Institution'})
          .then(response => {
            this.attributes = response.data.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    }, getAllFeatures() {
      featureService.allByInstitution(this.institution_id).then(response => {
        this.features = response.data.features
      }).catch(error => {
        this.features = [];
      })
    },

    manageFeature(item = {}, type = 'close') {
      if (type == "open") {
        if (item.id) {
          this.edit = true;
          this.feature = item;
        }
        this.dialog = true;
      } else {
        this.dialog = false;
        this.resetForm()
        this.getAllFeatures()
      }
    }
    ,
    createOrUpdate() {
      this.feature.institution_id = this.institution_id
      this.$v.feature.$touch();
      if (this.$v.feature.$error) {
        setTimeout(() => {
          this.$v.feature.$reset();
        }, 3000)
        return
      }
      if (!this.edit) this._create();
      else this.update();
    }
    ,
    _create: function () {
      this.loading = true;
      featureService
          .create(this.feature)
          .then(response => {
            this.$snotify.success("Features created successfully");
            this.manageFeature({}, 'close');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    }
    ,
    update: function () {
      this.loading = true;
      featureService
          .update(this.feature.id, this.feature)
          .then(response => {
            this.$snotify.success("State updated successfully");
            this.manageFeature({}, 'close');
          })
          .catch(error => {
            this.errors = error.errors;
          })
    }
    ,
    resetForm() {
      this.loading = false;
      this.edit = false;
      this.errors = [];
      this.feature = {
        attribute_id: null,
        institution_id: null,
        type: 'description',
        description: null
      };
      this.$v.feature.$reset();
    }
    ,
    deleteFeature(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item permanently?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            featureService.delete(id).then(response => {
              this.$snotify.success("Feature deleted successfully")
              this.getAllFeatures();
            }).catch(Error => {
              this.$snotify.error("Something went wrong please try agian later")
            })
          }
        }
      });
    }
  }
  ,
}
</script>

<style scoped>

</style>
