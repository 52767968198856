<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  {{ institution_id ? 'Update' : 'Add' }} University
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \
                  University
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <v-row>
              <v-tabs vertical>
                <v-tab v-if="checkIsAccessible('institution', 'show')">
                  <v-icon left>
                    mdi-account
                  </v-icon>
                  General Information
                </v-tab>
                <v-tab v-if="institution_id && checkIsAccessible('feature', 'show')">
                  <v-icon left>
                    mdi-lock
                  </v-icon>
                  Features
                </v-tab>
                <v-tab v-if="institution_id && checkIsAccessible('location', 'show')">
                  <v-icon left>
                    mdi-map
                  </v-icon>
                  Location
                </v-tab>
                <v-tab v-if="institution_id && checkIsAccessible('media', 'show')">
                  <v-icon left>
                    mdi-access-point
                  </v-icon>
                  Medias
                </v-tab>
                <v-tab v-if="institution_id && checkIsAccessible('required-document', 'show')">
                  <v-icon left>
                    mdi-file-document-box
                  </v-icon>
                  Required Documents
                </v-tab>
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col class="text-left" cols="12">
                          <h4>General Information</h4>
                        </v-col>
                        <v-col cols="4" lg="2" md="4">
                          <v-hover>
                            <template v-slot:default="{ hover }">
                              <v-avatar size="128" tile>
                                <v-img :src="logo_img" aspect-ratio="1"
                                       class="grey lighten-2">
                                  <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                      <span>Logo</span>
                                    </v-row>
                                  </template>
                                </v-img>
                                <v-fade-transition>
                                  <v-overlay
                                      absolute
                                      color="#036358"
                                      v-if="hover"
                                  >
                                    <v-btn @click="logoSelector=true" fab small>
                                      <v-icon>
                                        mdi-pencil
                                      </v-icon>
                                    </v-btn>
                                  </v-overlay>
                                </v-fade-transition>
                              </v-avatar>
                            </template>
                          </v-hover>

                        </v-col>
                        <v-col cols="8" lg="10" md="8">
                          <v-row>
                            <v-col cols="12">
                              <v-text-field :error="$v.university.title.$error" dense
                                            label="University Name"
                                            outlined
                                            v-model="university.title">
                                <template v-slot:label>
                                  University Name <span class="text-danger">*</span>
                                </template>
                              </v-text-field>
                              <span class="text-danger" v-if="$v.university.title.$error">Name is required</span>
                            </v-col>

                            <v-col cols="12" md="4">
                              <v-text-field
                                      prepend-inner-icon="mdi-calendar"
                                      dense
                                      label="Year Of Start"
                                      outlined
                                      placeholder="Year Of Start"
                                      v-model="university.founded_date">

                              </v-text-field>

                              <span class="text-danger"
                                    v-if="$v.university.founded_date.$error">Founded Date is required</span>

                            </v-col>
                            <v-col cols="12" md="4">
                              <v-select :error="$v.university.institution_type_id.$error"
                                        :items="institutions_types"
                                        dense item-text="title" item-value="id"
                                        label="University Type"
                                        outlined
                                        prepend-inner-icon="fas fa-university"
                                        v-model="university.institution_type_id"></v-select>
                              <span class="text-danger"
                                    v-if="$v.university.institution_type_id.$error">University Type  is required</span>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field dense label="DLI Number"
                                            outlined
                                            prepend-inner-icon="fas fa-hashtag"
                                            v-model="university.dli_number"></v-text-field>
                            </v-col>

                            <v-col cols="12" md="6">
                              <v-text-field dense label="Application Fee"
                                            outlined
                                            :error="$v.university.application_fee.$error"
                                            prepend-inner-icon="fas fa-money-bill-alt"
                                            :suffix="country ? country.currency : false"
                                            v-model="university.application_fee"></v-text-field>
                              <span class="text-danger" v-if="$v.university.application_fee.$error">Institution Application Fee is required</span>
                            </v-col>
                            <v-col cols="12" md="6">
                              <v-text-field dense label="Cost Of Living"
                                            outlined
                                            :error="$v.university.cost_of_living.$error"
                                            prepend-inner-icon="fas fa-money-bill-alt"
                                            :suffix="country ? country.currency : false"
                                            type="number"
                                            v-model="university.cost_of_living"></v-text-field>
                              <span class="text-danger" v-if="$v.university.cost_of_living.$error">Cost Of Living Fee is required</span>
                            </v-col>

                          </v-row>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-autocomplete
                              :loading="countryLoading"
                              :error="$v.university.country_id.$error"
                              :filter="customFilter"
                              :items="countries"
                              @change="changeCountry()"
                              dense
                              item-text="name"
                              item-value="id"
                              label="Country "
                              outlined
                              prepend-inner-icon="fas fa-globe-americas"
                              return-object
                              v-model="country"
                          >
                            <template v-slot:item="slotProps">
                              <i :class="`em em-flag-${slotProps.item.iso_2.toLowerCase()}`"
                                 class="mr-2"></i>
                              {{ slotProps.item.title }}
                            </template>
                            <template v-slot:selection="data">
                              <i :class="`em em-flag-${data.item.iso_2.toLowerCase()}`"
                                 class="mr-2"></i>
                              {{ data.item.title }}
                            </template>
                          </v-autocomplete>
                          <span class="text-danger" v-if="$v.university.country_id.$error">Country is required</span>

                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field
                              v-model="university.website_link"
                              label="University Website Link"
                              prepend-inner-icon="fas fa-globe"
                              prefix="https://"
                              outlined
                              dense
                          ></v-text-field>
                          <!--                          <span class="text-danger" v-if="$v.university.website_link.$error">University Website link is required</span>-->

                        </v-col>
                        <!--                      <pre>{{university}}</pre>-->
                        <v-col cols="12" md="3" v-if="country.title == 'Australia'">
                          <v-text-field
                              dense
                              outlined
                              v-model="university.abn"
                              type="number"
                          >
                            <template v-slot:label>
                              ABN <span class="text-danger"></span>
                            </template>
                          </v-text-field>
<!--                          <span class="text-danger" v-if="$v.university.abn.$error">ABN is required</span>-->
                        </v-col>

                        <v-col cols="12" md="3" v-if="country.title == 'Australia'">
                          <v-text-field
                              dense
                              outlined
                              v-model="university.acn"
                              type="number"
                          >
                            <template v-slot:label>
                              ACN <span class="text-danger"></span>
                            </template>
                          </v-text-field>
<!--                          <span class="text-danger" v-if="$v.university.acn.$error">ACN is required</span>-->
                        </v-col>

                        <v-col cols="12" md="3">
                          <v-switch label="Available for all country" v-model="university.available_for_all"></v-switch>
                        </v-col>

                        <v-col cols="12" md="12" v-if="!university.available_for_all">
                          <v-select
                              multiple
                              :items="all_countries"
                              dense
                              item-text="title"
                              item-value="id"
                              label="Nationality not allowed"
                              outlined
                              prepend-inner-icon="fas fa-globe-americas"
                              v-model="university.available_except"
                              :error="!university.available_for_all && $v.university.available_except.$error"
                          >
                          </v-select>
                          <span class="text-danger"
                                v-if="!university.available_for_all && $v.university.available_except.$error">Please select not allowed for admission nationality</span>
                        </v-col>

                        <v-col  md="12" cols="12">
                          <v-expansion-panels accordion>
                            <v-expansion-panel>
                              <v-expansion-panel-header><h4>Other</h4></v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-row class="m-2">
                                  <v-col cols="12" md="6">
                                    <v-text-field dense label="Total Students"
                                                  outlined
                                                  prepend-inner-icon="fas fa-user-graduate "
                                                  type="number"
                                                  v-model="university.total_student"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field dense label="International Students"
                                                  outlined
                                                  prepend-inner-icon="fas fa-user-graduate "
                                                  type="number"
                                                  v-model="university.total_international_student"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <VuePhoneNumberInput :default-country-code="default_country_code"
                                                         @update="phoneChanged"
                                                         v-model="university.phone_no"/>
                                    <!--                          <span class="text-danger"-->
                                    <!--                                v-if="$v.university.phone_no.$error">University Phone is required</span>-->

                                  </v-col>

                                  <v-col cols="12" md="6">
                                    <v-text-field
                                        dense
                                        label="University email"
                                        outlined
                                        prepend-inner-icon="fas fa-envelope"
                                        v-model="university.university_email"></v-text-field>
                                    <!--                          <span class="text-danger"-->
                                    <!--                                v-if="$v.university.university_email.$error">University Email is required</span>-->

                                  </v-col>

                                  <v-col cols="12" md="6">
                                    <v-text-field
                                        dense
                                        label="Finance email"
                                        outlined
                                        prepend-inner-icon="fas fa-envelope"
                                        v-model="university.finance_mail"></v-text-field>
                                  </v-col>

                                  <v-col cols="12" md="6">
                                    <v-combobox
                                        dense
                                        label="BBC send email"
                                        outlined
                                        multiple
                                        chips
                                        small-chips
                                        deletable-chips
                                        prepend-inner-icon="fas fa-envelope"
                                        v-model="university.bbc_send_mails"></v-combobox>
                                  </v-col>
                                </v-row>
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                        </v-col>

                      </v-row>

                      <v-row>
                        <v-col cols="12" md="6">
                          <v-text-field  v-model="university.trading_name" outlined dense label="Trading name"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field v-model="university.cricos_provider_code" outlined dense label="CRICOS Provider code"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field v-model="university.teqsa_code" outlined dense label="TESSA code"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <v-text-field v-model="university.rto_code" outlined dense label="RTO code"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6">
                          <label for>Description</label>
                          <ckeditor
                            :config="getEditorConfig()"
                            v-model="university.description"
                          ></ckeditor>

                        </v-col>

                        <v-col cols="6">
                          <label for>Agent note</label><span class="ml-3 text-danger">(You can write 160 letter)</span>
                          <ckeditor
                            :config="editorConfig1"
                            v-model="university.agent_note"
                          ></ckeditor>

                        </v-col>


                        <v-col cols="12" md="4">
                          <v-text-field
                              dense
                              label="Keywords"
                              outlined
                              v-model="university.keywords"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                              dense
                              label="Seo Title"
                              outlined
                              v-model="university.seo_title"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4">
                          <v-text-field
                              dense
                              label="Seo Keyword"
                              outlined
                              v-model="university.seo_keywords"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          Seo Description
                          <ckeditor
                              :config="getEditorConfig()"
                              v-model="university.seo_description"
                          >
                          </ckeditor>
                        </v-col>
                        <v-col cols="12" md="6">
                          Social Share Description
                          <ckeditor
                              :config="getEditorConfig()"
                              v-model="university.social_share_description"
                          >
                          </ckeditor>
                        </v-col>
                        <v-col cols="12" md="6">
                          Is Featured
                          <v-switch :label="university.is_featured ? 'Yes' : 'No'"
                                    v-model="university.is_featured"></v-switch>
                        </v-col>
                        <v-col cols="12" md="6">
                          Status
                          <v-switch
                              :label="university.is_active ? 'Active' : 'In-active'"
                              v-model="university.is_active"
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12 text-right">
                          <v-btn
                              @click="cancel"
                              color="mr-2" text dark
                          >Cancel
                          </v-btn>
                          <v-btn
                              v-if="checkIsAccessible('institution', 'create') || checkIsAccessible('institution', 'edit')"
                              :loading="loading"
                              @click="createOrUpdate"
                              color="ml-4 btn btn-primary"
                          >Save
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="checkIsAccessible('feature', 'show')">
                  <features :university="university"></features>
                </v-tab-item>
                <v-tab-item v-if="checkIsAccessible('location', 'show')">
                  <locations :university="university"></locations>
                </v-tab-item>
                <v-tab-item v-if="checkIsAccessible('media', 'show')">
                  <v-card flat>
                    <v-card-text>
                      <v-row class="mb-3">
                        <v-col class="text-left" cols="12">
                          <label for>Images</label>
                          <UploadImages @changed="handleImages"/>
                        </v-col>
                      </v-row>
                      <v-row v-if="medias.length > 0">
                        <v-col
                            v-for="(media,index) in medias"
                            :key="index"
                            class="d-flex child-flex"
                            cols="2"
                        >
                          <v-hover>
                            <template v-slot:default="{ hover }">
                              <v-avatar size="128" tile>
                                <v-img :src="media.image_path['real']"
                                       :lazy-src="`https://picsum.photos/10/6?image=${index * 5 + 10}`">
                                  <template v-slot:placeholder>
                                    <v-row
                                        class="fill-height ma-0"
                                        align="center"
                                        justify="center"
                                    >
                                      <v-progress-circular
                                          indeterminate
                                          color="grey lighten-5"
                                      ></v-progress-circular>
                                    </v-row>
                                  </template>
                                </v-img>
                                <v-fade-transition>
                                  <v-overlay
                                      absolute
                                      color="#036358"
                                      v-if="hover"
                                  >
                                    <v-btn @click="__removeImage(media)" fab small>
                                      <v-icon>
                                        fas fa-trash
                                      </v-icon>
                                    </v-btn>
                                  </v-overlay>
                                </v-fade-transition>
                              </v-avatar>
                            </template>
                          </v-hover>
                        </v-col>
                      </v-row>

                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="checkIsAccessible('required-document', 'show')">
                  <required-document :item="university"></required-document>
                </v-tab-item>
              </v-tabs>

            </v-row>

          </div>
        </div>
      </div>
    </div>
    <v-dialog
        max-width="900"
        v-model="logoSelector"
    >
      <v-card>
        <v-card-title class="text-h5">
          Choose Logo
        </v-card-title>

        <v-card-text>
          Choose to change a University logo and crop
          <v-row class="mt-2">
            <v-col cols="6">
              <cropper
                  v-if="img"
                  :src="img"
                  @change="change"
                  class="cropper"
              ></cropper>
            </v-col>
            <v-col>
              <v-file-input
                  :rules="rules"
                  @change="changeImage"
                  accept="image/png, image/jpeg, image/jpg"
                  dense
                  label="Logo"
                  outlined
                  placeholder="Pick a logo"
                  prepend-icon="mdi-camera"
                  v-model="logo_img"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              @click="resetLogo"
              color="mr-2" text dark
          >
            Cancel
          </v-btn>
          <v-btn
              :loading="loading"
              @click="uploadLogo"
              color="ml-4 btn btn-primary"
          >
            Upload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

import CountryService from "@/services/country/CountryService";
import InstitutionTypesService from "@/services/academic/institution/type/InstitutionTypesService";
import { required } from "vuelidate/lib/validators";
import { Cropper, Preview} from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import UploadImages from "vue-upload-drop-images"

import InstitutionService from "@/services/academic/institution/InstitutionService";
import Locations from "./sections/Locations";
import Features from "./sections/Features";
import MediaService from "@/services/academic/institution/media/MediaService";
import RequiredDocument from "./sections/RequiredDocument";

const country = new CountryService();
const institutionTypesService = new InstitutionTypesService()
const institutionService = new InstitutionService();
const mediaService = new MediaService();

export default {
  name: "CreateAndUpdate",
  components: {
    Cropper,
    Preview,
    VuePhoneNumberInput,
    UploadImages,
    Locations,
    Features,
    RequiredDocument
  },
  data() {
    return {
      title: '',
      menu: false,
      loading: false,
      logoSelector: false,
      countryLoading: false,
      activePicker: null,
      date: null,
      select: null,
      website_link: null,
      base64Img: null,
      previewImg: null,
      country: {},
      countryDetail: {},
      default_country_code: 'NP',
      institutions_types: [],
      files: [],
      medias: [],
      university_type: [
        {
          id: 1,
          name: "Public",

        },
        {
          id: 2,
          name: "Government",

        },
        {
          id: 3,
          name: "Private",

        },
      ],
      all_countries: [],
      countries: [
        {
          name: "Andorra",
          flag: "em-flag-ad"
        },
        {
          name: "Arab Emirates",
          flag: "em-flag-ae"
        },
        {
          name: "Afghanistan",
          flag: "em-flag-af"
        },
        {
          name: "Antigua & Barbuda",
          flag: "em-flag-ag"
        },
        {
          name: "Albania",
          flag: "em-flag-al"
        },
        {
          name: "Anguilla",
          flag: "em-flag-ai"
        }
      ],
      university: {
        title: '',
        slug: '',
        image: '',
        logo_image: '',
        founded_date: '',
        institution_type_id: '',
        application_fee: '',
        total_student: '',
        total_international_student: '',
        features: '',
        country_id: '',
        country_code: '',
        phone_no: '',
        university_email: '',
        address: '',
        website_link: '',
        trading_name: '',
        cricos_provider_code: '',
        teqsa_code: '',
        rto_code: '',
        google_map_location: '',
        google_street_map: '',
        description: '',
        agent_note: '',
        abn: '',
        acn: '',
        finance_mail: '',
        bbc_send_mails: [],
        keywords: '',
        seo_keywords: '',
        seo_title: '',
        seo_description: '',
        social_share_image: '',
        social_share_description: '',
        cost_of_living: '',
        is_featured: false,
        available_for_all: '',
        available_except: '',
        dli_number: '',
        is_active: true,
      },
      institution_id: null,
      rules: [
        value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
      ],
      errors: [],
      attributes: [],
      img: null,
      logo_img: null,
      editorConfig: {
        versionCheck: false,
        scayt_autoStartup: true,
        allowedContent: true,
        pasteFromWordRemoveFontStyles: true,
        pasteFromWordRemoveStyles: true,
        pasteFromWordNumberedHeadingToList: true,
        toolbar: [['Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'SpellChecker', 'Scayt', 'PasteFromWord', 'TextColor', 'BGColor']],
        contentsCss: [
          'http://cdn.ckeditor.com/4.17.1/full-all/contents.css',
          'https://ckeditor.com/docs/ckeditor4/4.17.1/examples/assets/css/pastefromgdocs.css'
        ],
        extraPlugins: ['font', 'colorbutton'],
        colorButton_enableMore: true,
      },
      editorConfig1: {
        toolbar: [['Font', 'FontSize', 'FontColor', 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList', 'SpellChecker', 'Scayt', 'PasteFromWord', 'TextColor', 'BGColor']],
      },
    }
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  validations() {
    return {
      university: this.university_rule
    }
  },
  computed: {
    years() {
      let cYear = new Date().getFullYear();
      let max = cYear-50;
      let years = [];
      for (let cYear = 2022; cYear >= max; cYear--) {
        years.push(cYear);
      }
      return years;
    },
    university_rule() {
      let rule = {
        title: {required},
        founded_date: {required},
        institution_type_id: {required},
        country_id: {required},
        application_fee: {required},
        cost_of_living: {required},
      }
      if (!this.university.available_for_all)
        rule.available_except = {required}
      return rule;
    }
  },
  mounted() {
    this.institution_id = this.$route.params.institution_id;
    this.__detail();
    this.getCountries();
    this.getAllCountryList();
    this.getInstitutionTypes();
    this.editorConfig1 = this.editorConfig
    this.editorConfig2 = this.editorConfig
  },
  methods: {
    getEditorConfig(){
      return {
        toolbar: [
          [
            'Font',
            'FontSize',
            'FontColor',
            'Bold',
            'Italic',
            'Underline',
            'Strike',
            'RemoveFormat',
            'Link',
            'NumberedList',
            'BulletedList',
            'SpellChecker',
            'Scayt',
            'PasteFromWord',
            'TextColor',
            'BGColor'
          ]
        ],
      }
    },
    getAllCountryList() {
      country.getAllCountry().then(response => {
        this.all_countries = response.data.data
      })
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    customFilter(item, queryText, itemText) {
      const textOne = item.title.toLowerCase()
      const searchText = queryText.toLowerCase()
      return textOne.indexOf(searchText) > -1
    },
    phoneChanged(phone) {
      this.university.country_code = phone.countryCallingCode;
    },

    handleImages(files) {
      this.files = files
      this.__upload();
    },

    convertToFormData() {
      let formData = new FormData();
      for (let key in this.university) {
        if (key === "file" && this.university[key] != null && this.university[key] != undefined) {
          formData.append('file', this.university[key]);
        } else {
            formData.append(key, this.university[key] || '' );
        }
      }
      return formData;
    },

    __upload() {
      const formData = new FormData();
      // this.isBusy = true;
      this.files.forEach(file => {
        if (file) {
          formData.append("image[]", file);
          formData.append("institution_id", this.university.id);
        }
      });

      // alert("the total size uploaded is " + this.getFileSize(this.totalSize));
      mediaService.create(formData).then(res => {
        // this.isBusy = false;
        this.$snotify.success("Uploaded");
        // this.images = [];
        // this.files = [];
        // this.getMedia();
      });
    },

    __getMedias() {
      mediaService.getInstitutionAddress(this.institution_id).then(res => {
        this.medias = res.data.medias;
      }).catch(error => {
      })
    },

    changeImage(e) {
      this.img = URL.createObjectURL(this.logo_img)
    },

    resetLogo() {
      this.base64Img = null;
      this.img = null;
      this.logoSelector = false;
    },

    change({coordinates, canvas}) {
      this.base64Img = canvas.toDataURL();
      this.logo_img = this.base64Img;
    },

    __detail() {
      if (this.institution_id != null || this.institution_id != undefined) {
        institutionService
            .show(this.institution_id)
            .then(response => {
              this.university = response.data.university;
              if (this.university.logo_image_path && this.university.logo_image_path.thumb != null) {
                this.logo_img = this.university.logo_image_path.real;
              }
              if(this.university.website_link) {
                this.website_link = this.university.website_link;
                this.university.website_link = this.university.website_link.includes("https://") ? this.university.website_link.replace('https://', '') : '';
              }
              this.__getMedias();
              this.getCountryById();
            }).catch(err => {
          this.$snotify.error("Oops something went wrong");
        });
      }
    },

    getCountryById() {
      this.countryLoading = true;
      country.getById(this.university.country_id).then(res => {
        this.country = res.data.country;
        this.changeCountry();
      }).catch(error => {
      }).finally(() => {
        this.countryLoading = false;
      })
    },

    previewImage() {
      if (this.university.file != null) {
        this.imgurl = URL.createObjectURL(this.university.file)
      } else {
        this.imgurl = null;
      }
    },

    getCountries() {
      country
          .getAllCountry({'is_operating_country': 1})
          .then((response) => {
            this.countries = response.data.data;
          })
          .catch((error) => {
          })
    },

    changeCountry() {
      this.university.country_id = this.country.id;
      this.university.country_code = this.country.phone_code;
      this.default_country_code = this.country.iso_2;
    },

    getInstitutionTypes() {
      this.isLoading = true;
      institutionTypesService
          .all()
          .then(response => {
            this.institutions_types = response.data.data;
            this.isLoading = false;
          })
          .catch((err) => {
          }).finally(() => {
        this.isLoading = false;
      });
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        this.loading = true;
        if(this.university.website_link && !this.university.website_link.includes("https://") || this.website_link && !this.website_link.includes("https://")) {
          this.university.website_link = "https://" + this.university.website_link;
        }
        if (this.institution_id == null || this.institution_id == undefined) this._create();
        else this.update();
      }
    },

    cancel() {
      this.$tabs.close();
    },

    _create: function () {
      let formData = this.convertToFormData();
      institutionService
          .create(formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Institution created successfully");
            this.resetForm();
            this.$tabs.close();
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },

    update() {
      let formData = this.convertToFormData();
      institutionService
          .update(this.university.id, formData)
          .then(response => {
            this.loading = false;
            this.$snotify.success("Institution updated successfully");
            this.resetForm();
            this.$tabs.close();
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
            this.errors = err.errors;
          });
    },

    uploadLogo() {
      if(this.base64Img) {

        if(this.university.id) {
          this.loading = true
          let data = {
            'logo_image_file': this.base64Img
          }
          institutionService
              .saveCropperImage(this.university.id, data)
              .then(response => {
                this.loading = false;
                this.logoSelector = false;
                this.$snotify.success("Logo Uploaded Successfully");
                this.__detail();
              })
              .catch((err) => {
                this.loading = false;
                this.$snotify.error("Oops something went wrong");
                this.errors = err.errors;
              });
        } else {
          this.university.logo_image_file = this.base64Img;
          this.resetLogo();
        }
      } else {
        this.$snotify.error("Please upload an logo");
      }
    },

    __removeImage(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            mediaService
                .delete(item.id)
                .then((response) => {
                  this.__getMedias()
                  this.$snotify.success("Image Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },

    resetForm() {
      this.$v.$reset();
      this.university = {
        title: '',
        slug: '',
        image: '',
        logo_image: '',
        founded_date: '',
        institution_type_id: '',
        application_fee: '',
        total_student: '',
        total_international_student: '',
        features: '',
        country_id: '',
        country_code: '',
        phone_no: '',
        university_email: '',
        address: '',
        website_link: '',
        trading_name: '',
        cricos_provider_code: '',
        teqsa_code: '',
        rto_code: '',
        google_map_location: '',
        google_street_map: '',
        description: '',
        agent_note: '',
        abn: '',
        acn: '',
        finance_mail: '',
        bbc_send_mails: [],
        keywords: '',
        seo_keywords: '',
        seo_title: '',
        seo_description: '',
        social_share_image: '',
        social_share_description: '',
        cost_of_living: '',
        is_featured: false,
        available_for_all: '',
        available_except: '',
        dli_number: '',
        is_active: true,
      }
    },
  }
}
</script>
<style>
@import 'https://emoji-css.afeld.me/emoji.css';
.vue-advanced-cropper__background {
  background: #0000 !important;
}
</style>
