import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class LocationService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/location';
    }

    all(universityId) {
        let url = `${this.#api}/${universityId}/get/all`;
        return apiService.get(url);
    }

    getInstitutionsLocations(universityId, programId, sessionId) {
        let url = `${this.#api}/${universityId}/get/${programId}/${sessionId}`;
        return apiService.get(url)
    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url)
    }

    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data)
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data)
    }

    delete(programId) {
        let url = `${this.#api}/${programId}`
        return apiService.delete(url)
    }

    updateProgramLocation(id, data) {
        let url = `${this.#api}/${id}/academic-program`;
        return apiService.post(url, data)
    }

    deleteProgramLocation(id, programId) {
        let url = `${this.#api}/${id}/program/${programId}`
        return apiService.post(url)
    }

    getInstitutionAddress(institutionId, data = {}) {
        let url = `${this.#api}/${institutionId}/get/for-institution`;
        let param = {
            params: data
        }
        return apiService.query(url, param)
    }

    getInstitutionAddressForProgram(institutionId, programId) {
        let url = `${this.#api}/${institutionId}/by-institution/${programId}/for-program`
        return apiService.get(url)
    }

    getProgramAddress(programId, sessionId) {
        let url = `${this.#api}/${programId}/get/${sessionId}/all-for-program`
        return apiService.get(url)
    }

    updateForProgramAndSession(id, data) {
        let url = `${this.#api}/${id}/update/program-session`
        return apiService.post(url, data)
    }
}
